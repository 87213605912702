.profile {
  display: flex;
  gap: 5px;
  height: 40px;
  font-size: 20px;
  position: absolute;
  top: 15px;
  right: 20px;
}

.user-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
