.door {
  background-color: #01a141;
  color: #bbffff;
  width: 40px;
  height: 40px;
  font-size: 1em;
  text-align: center;
  position: absolute;
}

.return {
  background-color: #397fd4;
}
