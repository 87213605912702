.title {
  margin: auto;
  text-align: center;
  /* Visual Effects below */
  user-select: none;
  font-size: 40px;
	background-image: linear-gradient(-45deg, 
    #42cbf5,
    #6a41f0,
    #c7504a,
    #e89a3e,
    #29ba44,
    #ebe544
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 800% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 15s alternate-reverse infinite;
  text-shadow: #ffffffc0 1px 0 8px;
}

@keyframes textclip {
  to {
    background-position: 100%;
  }
}

.home-page {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.home-body {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 0.5em;
  text-align: center;
}

.home-info {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 0.5em;
  text-align: center;
  color: blue;
}