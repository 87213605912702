.intro-title {
  margin: auto;
  text-align: center;
}

body {
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #cecece 1px, transparent 1px);
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: 20px 20px;
}

.page {
}

