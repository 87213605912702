.arrows {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 20%;
  position: absolute;
}

.arrow {
  width: 30%;
  height: 100%;
  width: 100%;
}

.arrow-vertical {
  width: 100%;
  height: 50%;
  width: 100%;
}

.vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
